import React, { PureComponent, useEffect } from "react";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import { Link } from "react-router-dom";
import Baccarat from "../../../../assets/images/static_pages/Bacarattt.jpg";
import CrickSec2 from "../../../../assets/images/static_pages/fairbet7.jpg";
import CrickSec3 from "../../../../assets/images/static_pages/diamond_exch.jpg";
import CrickSec4 from "../../../../assets/images/static_pages/play.jpg";
import CrickSec5 from "../../../../assets/images/static_pages/sky_exchange.jpg";
import CrickSec6 from "../../../../assets/images/static_pages/world777.jpg";
import CrickSec7 from "../../../../assets/images/static_pages/lotus.jpg";
import CrickSec8 from "../../../../assets/images/static_pages/goldbet7.jpg";
import WhatsAppIcon from "../../../../assets/images/static_pages/whatsapp.png";
import Partner1 from "../../../../assets/images/static_pages/cricket.jpg";
import Partner2 from "../../../../assets/images/static_pages/football.jpg";
import Partner3 from "../../../../assets/images/static_pages/card_games.jpg";
import Partner4 from "../../../../assets/images/static_pages/teenpati.jpg";
import Partner5 from "../../../../assets/images/static_pages/casino.jpg";
import Partner6 from "../../../../assets/images/static_pages/binary.jpg";
import Partner7 from "../../../../assets/images/static_pages/slot-games.jpg";
import Partner8 from "../../../../assets/images/static_pages/horse_racing.jpg";
import BestIcon1 from "../../../../assets/images/static_pages/da_icon1.png";
import BestIcon2 from "../../../../assets/images/static_pages/da_icon2.png";
import BestIcon3 from "../../../../assets/images/static_pages/da_icon3.png";
import BestIcon4 from "../../../../assets/images/static_pages/da_icon4.png";
import BestIcon5 from "../../../../assets/images/static_pages/da_icon5.png";
import BestIcon6 from "../../../../assets/images/static_pages/da_icon6.png";
import BestIcon7 from "../../../../assets/images/static_pages/da_icon7.png";
import BestIcon8 from "../../../../assets/images/static_pages/da_icon8.png";
import Whychoose from "../../../../assets/images/static_pages/whychoose_bg2.png";
import WaitingIcon1 from "../../../../assets/images/static_pages/dw_icon1.png";
import WaitingIcon2 from "../../../../assets/images/static_pages/dw_icon2.png";
import WaitingIcon3 from "../../../../assets/images/static_pages/dw_icon3.png";
import WaitingIcon4 from "../../../../assets/images/static_pages/dw_icon4.png";
import WaitingIcon5 from "../../../../assets/images/static_pages/dw_icon5.png";
import WaitingIcon6 from "../../../../assets/images/static_pages/dw_icon6.png";
import { addMetaTags } from "../../../../utils/addMetaTags";

const BaccaratPage = () => {
  useEffect(() => {
    addMetaTags({
      title:
        "Play Live Baccarat Casino Game In India | Baccarat Game Online Free | Sky888",
      content:
        "Find baccarat game online at Sky888! Play live baccarat online and earn money! Discover free online baccarat casino games and place your bet safe and secure with us!",
      canonicalUrl: "https://sky888.com/baccarat-game",
    });
  }, []);
  console.log("test", document.querySelector("link[rel='canonical']"));
  // document
  //   .querySelector("link[rel='canonical']")
  //   .setAttribute("href", "https://sky888.com/cricket-betting/");
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main">
          <section className="dial_slider_main dial_slider_main_baccarat">
            <div className="container"></div>
          </section>

          <section className="dial_sec_space dial_sec_two">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Top Betting Id</h2>
              </div>

              <div className="dial_sec_grid_betting_id">
                <ul>
                  <li>
                    <img src={CrickSec2} alt="Fairbet7" />
                  </li>
                  <li>
                    <img src={CrickSec3} alt="Diamond Exch" />
                  </li>
                  <li>
                    <img src={CrickSec4} alt="Play" />
                  </li>
                  <li>
                    <img src={CrickSec5} alt="Sky Exchange" />
                  </li>
                  <li>
                    <img src={CrickSec6} alt="World 777" />
                  </li>
                  <li>
                    <img src={CrickSec7} alt="Lotus" />
                  </li>
                  <li>
                    <img src={CrickSec8} alt="Goldbet7" />
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_three">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Our Partners</h2>
              </div>

              <div className="dial_partners_grid">
                <div className="row">
                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Cricket</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner1} alt="Cricket" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Sportsbook</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner2} alt="Football" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Card Games</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner3} alt="Card Games" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Teenpatti Multiplayer</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner4} alt="Teen Pati" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Casino</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner5} alt="Casino" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Binary</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner6} alt="Binary" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Slot Games</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner7} alt="Slot Games" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Greyhound & Horse Racing</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner8} alt="Horse Racing" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_four">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Why Choose Us</h2>
              </div>

              <div className="dial_whychoose_grid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="whychoose_box">
                      <div className="why_choose_title">
                        <h4>Best Achievements</h4>
                      </div>

                      <div className="why_choose_grid">
                        <ul>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon1} alt="Icon" />
                            </span>
                            <span className="dial_icontext">Winners</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon2} alt="Icon" />
                            </span>
                            <span className="dial_icontext">24x7 Support</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon3} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              100% Trusted Platform
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon4} alt="Icon" />
                            </span>
                            <span className="dial_icontext">24M+ Users</span>
                          </li>
                        </ul>
                        <div className="dial_choose_btn">
                          <Link to="/">Win Big</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="whychoose_box whychoose_box2">
                      <div className="why_choose_title">
                        <h4>Why Choose Us</h4>
                      </div>
                      <div className="why_choose_grid">
                        <ul>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon5} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Instant Withdrawal
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon6} alt="Icon" />
                            </span>
                            <span className="dial_icontext">Best Platform</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon7} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Players Online
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon8} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Working Since 2010
                            </span>
                          </li>
                        </ul>
                        <div className="dial_choose_btn">
                          <Link to="/">Click Get Your ID</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_five">
            <div className="container">
              <div className="dial_ads_infos">
                <h3>Fast Withdrawal Get 15% cashback Betting Id</h3>
                <p>
                  This is a very easy way to withdraw cash and we are providing
                  the best offer for new users.
                </p>
                <div className="ads_btns">
                  <Link to="/">+9198888899999</Link>
                  <Link to="/" className="dial_booknow">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_one">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Baccarat</h2>
              </div>
              <div className="dial_first_block">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dial_img_block">
                      <img src={Baccarat} alt="Cricket Image" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="dial_right_infos">
                      <h1>
                        Baccarat Game Online: The Ultimate Experience of Playing
                        at Sky888
                      </h1>
                      <p>
                        If you're looking for an exciting, fast-paced card game,
                        the <b>baccarat game online</b> at Sky888 is a perfect
                        choice. Baccarat is one of the most popular casino games
                        worldwide, and with good reason. It's simple to learn,
                        offers great odds, and provides a thrilling experience.
                        At Sky888, we've made it easy for you to enjoy this
                        classic <b>free baccarat</b>game from the comfort of
                        your own home.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dial_first_block dial_first_block_more">
                <h2>Baccarat Casino Game: A Classic Experience Online</h2>
                <p>
                  The
                  <b> baccarat casino game</b>has been a favorite among
                  casino-goers for centuries. The game involves betting on the
                  outcome of two hands—the Player and the Banker. The objective
                  is to predict which hand will have a total closest to nine.
                  The simplicity of the game, combined with the excitement of
                  placing bets, makes <b>baccarat game online</b> a compelling
                  choice for both new and experienced players.
                </p>

                <p>
                  Playing the <b>baccarat card game online</b> at Sky888 is
                  straightforward. You start by choosing your stakes and placing
                  your bet on either the Banker, Player, or a Tie. The game then
                  proceeds with two hands being dealt—one for the Player and one
                  for the Banker. The hand with a total closest to nine wins. If
                  you bet on the winning hand, you receive a payout based on the
                  type of bet you placed.
                </p>

                <h2>Understanding the Payouts in Baccarat </h2>

                <ul className="gameList">
                  <li>
                    <b>Player Bet : </b> Pays 1/1 with a house edge of 1.29%
                    (Single Deck) and 1.24% (6-Deck).
                  </li>
                  <li>
                    <b>Banker Bet : </b> Pays 19/20 (5% commission) with a house
                    edge of 1.01% (Single Deck) and 1.06% (6-Deck).
                  </li>

                  <li>
                    <b>Tie : </b> Pays 8/1 with a house edge of 15.75% (Single
                    Deck) and 14.44% (6-Deck).
                  </li>
                </ul>

                <p>
                  These simple <b> baccarat game online</b> payout structures
                  make it easy to understand how much you can win based on your
                  bets.
                </p>

                <h2>Free Baccarat: Try Before You Bet</h2>
                <p>
                  For those new to the game or looking to practice their
                  strategy, <b>free baccarat</b> is a fantastic option. At
                  Sky888, we offer free versions of the game so you can get a
                  feel for it before wagering real money. This allows you to
                  understand the gameplay, test different strategies, and build
                  your confidence without any financial risk.
                </p>

                <h2>Real-Time Action at Baccarat Live Casino</h2>
                <p>
                  Experience the thrill of a real casino from your home with our{" "}
                  <b>baccarat live casino games.</b> At Sky888, we offer live
                  dealer baccarat games where you can interact with a
                  professional dealer in real-time. This immersive{" "}
                  <b>baccarat casino game</b> experience brings the excitement
                  of a physical casino straight to your screen, making it one of
                  the most popular options for online players.
                </p>

                <h2>Betting Options in Online Baccarat</h2>
                <p>
                  When you <b>play baccarat game online,</b> you have several
                  betting options:
                </p>

                <ul className="gameList">
                  <li>
                    <b>Player Bet: </b> You win if the Player's hand is closer
                    to nine.
                  </li>
                  <li>
                    <b>Banker Bet: </b> You win if the Banker's hand is closer
                    to nine.
                  </li>
                  <li>
                    <b>Tie Bet : </b> You win if both hands have the same total.
                  </li>
                </ul>
                <p>
                  Additionally, there are various side bets available in{" "}
                  <b>baccarat card game online </b>, such as Player Pair, Banker
                  Pair, and Perfect Pair, each with its own payout and house
                  edge.
                </p>

                <h2>Play Baccarat Game: Simple and Exciting</h2>
                <p>
                  To <b>play baccarat game</b> at Sky888, simply log in to your
                  account, choose your preferred baccarat version, and place
                  your bets. The interface is user-friendly, ensuring that you
                  can easily<b>play baccarat game</b> and place your bets
                  without any hassle. Whether you’re betting on the Player,
                  Banker, or a Tie, the excitement of watching the cards being
                  dealt and the anticipation of winning is unmatched.
                </p>

                <h2>Common Bets in Online Baccarat Games </h2>
                <ul className="gameList">
                  <li>
                    <b>Player Pair: </b> Pays 11/1 with a house edge of 11.25%.
                  </li>
                  <li>
                    <b>Banker Pair: </b> Pays 11/1 with a house edge of 11.25%.
                  </li>
                  <li>
                    <b>Perfect Pair : </b> Pays 25/1 with a house edge of
                    17.07%.
                  </li>
                  <li>
                    <b>Either Pair : </b> Pays 5/1 with a house edge of 14.54%.
                  </li>
                  <li>
                    <b>Small : </b> Pays 1.5/1 with a house edge of 5.27%.
                  </li>
                  <li>
                    <b>Big : </b> Pays 0.54/1 with a house edge of 4.35%.
                  </li>
                </ul>
                <p>
                  These side bets add an extra layer of excitement to the game,
                  providing more ways to win.
                </p>

                <h2>Best Live Baccarat: Sky888's Commitment</h2>
                <p>
                  At Sky888, we pride ourselves on being the{" "}
                  <b>best live baccarat casino.</b> Our live dealer games are
                  streamed in high definition, ensuring a seamless and immersive
                  experience. The professional dealers, real-time interaction,
                  and high-quality gameplay make Sky888 the go-to destination
                  for live baccarat enthusiasts.
                </p>

                <h2>Practice and Play Baccarat Casino Game Online at Sky888</h2>
                <p>
                  For those who want to practice without spending money, Sky888
                  offers <b>baccarat casino game online free.</b> This allows
                  you to enjoy the game and understand its dynamics without any
                  financial commitment. It’s an excellent way to get comfortable
                  with the game before moving on to real money bets.
                </p>
                <p>
                  The <b>baccarat card game online</b> at Sky888 offers a
                  thrilling and accessible way to enjoy this classic casino
                  game. Whether you're a novice or a seasoned player, our
                  platform provides everything you need for an exciting baccarat
                  experience. With options for free play, live dealer games, and
                  various betting strategies, Sky888 ensures that your baccarat
                  gaming is both fun and rewarding. Join us today and immerse
                  yourself in the world of online baccarat.
                </p>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_six">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Waiting for Booking</h2>
              </div>
              <div className="dial_sub_text">
                We have some great and good features due to which you will feel
                good in joining us. You will get hundred percent support from
                our side.
              </div>

              <div className="dial_waiting_grid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon dial_waiting_icon_small">
                        <img src={WaitingIcon1} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Safe & Secure</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon2} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Active Users</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon3} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Replay on Whatsapp</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dial_wgrid_img">
                      <img src={Whychoose} alt="WhychooseImage" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon4} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Branches</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon5} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>24/7 Hours Services</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon">
                        <img src={WaitingIcon6} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>On Time & Fast Delivery</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default BaccaratPage;
