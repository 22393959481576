import React, { PureComponent, useEffect } from "react";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import { Link } from "react-router-dom";
import CardGame from "../../../../assets/images/static_pages/ThreeCardGamee.jpg";
import CrickSec2 from "../../../../assets/images/static_pages/fairbet7.jpg";
import CrickSec3 from "../../../../assets/images/static_pages/diamond_exch.jpg";
import CrickSec4 from "../../../../assets/images/static_pages/play.jpg";
import CrickSec5 from "../../../../assets/images/static_pages/sky_exchange.jpg";
import CrickSec6 from "../../../../assets/images/static_pages/world777.jpg";
import CrickSec7 from "../../../../assets/images/static_pages/lotus.jpg";
import CrickSec8 from "../../../../assets/images/static_pages/goldbet7.jpg";
import WhatsAppIcon from "../../../../assets/images/static_pages/whatsapp.png";
import Partner1 from "../../../../assets/images/static_pages/cricket.jpg";
import Partner2 from "../../../../assets/images/static_pages/football.jpg";
import Partner3 from "../../../../assets/images/static_pages/card_games.jpg";
import Partner4 from "../../../../assets/images/static_pages/teenpati.jpg";
import Partner5 from "../../../../assets/images/static_pages/casino.jpg";
import Partner6 from "../../../../assets/images/static_pages/binary.jpg";
import Partner7 from "../../../../assets/images/static_pages/slot-games.jpg";
import Partner8 from "../../../../assets/images/static_pages/horse_racing.jpg";
import BestIcon1 from "../../../../assets/images/static_pages/da_icon1.png";
import BestIcon2 from "../../../../assets/images/static_pages/da_icon2.png";
import BestIcon3 from "../../../../assets/images/static_pages/da_icon3.png";
import BestIcon4 from "../../../../assets/images/static_pages/da_icon4.png";
import BestIcon5 from "../../../../assets/images/static_pages/da_icon5.png";
import BestIcon6 from "../../../../assets/images/static_pages/da_icon6.png";
import BestIcon7 from "../../../../assets/images/static_pages/da_icon7.png";
import BestIcon8 from "../../../../assets/images/static_pages/da_icon8.png";
import Whychoose from "../../../../assets/images/static_pages/whychoose_bg2.png";
import WaitingIcon1 from "../../../../assets/images/static_pages/dw_icon1.png";
import WaitingIcon2 from "../../../../assets/images/static_pages/dw_icon2.png";
import WaitingIcon3 from "../../../../assets/images/static_pages/dw_icon3.png";
import WaitingIcon4 from "../../../../assets/images/static_pages/dw_icon4.png";
import WaitingIcon5 from "../../../../assets/images/static_pages/dw_icon5.png";
import WaitingIcon6 from "../../../../assets/images/static_pages/dw_icon6.png";
import { addMetaTags } from "../../../../utils/addMetaTags";

const CardGamePage = () => {
  useEffect(() => {
    addMetaTags({
      title: "3 Card Casino Game In India | Play 3 Card Game Online | Sky888",
      content:
        "Dive into the excitement with 3 card game online at Sky888. Play 3 patti cards for real money and win big while having fun! Explore big 3 card game with us!",
      canonicalUrl: "https://sky888.com/3-card-game",
    });
  }, []);
  console.log("test", document.querySelector("link[rel='canonical']"));
  // document
  //   .querySelector("link[rel='canonical']")
  //   .setAttribute("href", "https://sky888.com/cricket-betting/");
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main">
          <section className="dial_slider_main dial_slider_main_cardgame">
            <div className="container"></div>
          </section>

          <section className="dial_sec_space dial_sec_two">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Top Betting Id</h2>
              </div>

              <div className="dial_sec_grid_betting_id">
                <ul>
                  <li>
                    <img src={CrickSec2} alt="Fairbet7" />
                  </li>
                  <li>
                    <img src={CrickSec3} alt="Diamond Exch" />
                  </li>
                  <li>
                    <img src={CrickSec4} alt="Play" />
                  </li>
                  <li>
                    <img src={CrickSec5} alt="Sky Exchange" />
                  </li>
                  <li>
                    <img src={CrickSec6} alt="World 777" />
                  </li>
                  <li>
                    <img src={CrickSec7} alt="Lotus" />
                  </li>
                  <li>
                    <img src={CrickSec8} alt="Goldbet7" />
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_three">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Our Partners</h2>
              </div>

              <div className="dial_partners_grid">
                <div className="row">
                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Cricket</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner1} alt="Cricket" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Sportsbook</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner2} alt="Football" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Card Games</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner3} alt="Card Games" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Teenpatti Multiplayer</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner4} alt="Teen Pati" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Casino</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner5} alt="Casino" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Binary</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner6} alt="Binary" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Slot Games</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner7} alt="Slot Games" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Greyhound & Horse Racing</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner8} alt="Horse Racing" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_four">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Why Choose Us</h2>
              </div>

              <div className="dial_whychoose_grid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="whychoose_box">
                      <div className="why_choose_title">
                        <h4>Best Achievements</h4>
                      </div>

                      <div className="why_choose_grid">
                        <ul>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon1} alt="Icon" />
                            </span>
                            <span className="dial_icontext">Winners</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon2} alt="Icon" />
                            </span>
                            <span className="dial_icontext">24x7 Support</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon3} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              100% Trusted Platform
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon4} alt="Icon" />
                            </span>
                            <span className="dial_icontext">24M+ Users</span>
                          </li>
                        </ul>
                        <div className="dial_choose_btn">
                          <Link to="/">Win Big</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="whychoose_box whychoose_box2">
                      <div className="why_choose_title">
                        <h4>Why Choose Us</h4>
                      </div>
                      <div className="why_choose_grid">
                        <ul>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon5} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Instant Withdrawal
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon6} alt="Icon" />
                            </span>
                            <span className="dial_icontext">Best Platform</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon7} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Players Online
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon8} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Working Since 2010
                            </span>
                          </li>
                        </ul>
                        <div className="dial_choose_btn">
                          <Link to="/">Click Get Your ID</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="dial_sec_space dial_sec_five">
            <div className="container">
              <div className="dial_ads_infos">
                <h3>Fast Withdrawal Get 15% cashback Betting Id</h3>
                <p>
                  This is a very easy way to withdraw cash and we are providing
                  the best offer for new users.
                </p>
                <div className="ads_btns">
                  <Link to="/">+9198888899999</Link>
                  <Link to="/" className="dial_booknow">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_one">
            <div className="container">
              <div className="dial_sec_title">
                <h2>3 Card Game</h2>
              </div>
              <div className="dial_first_block">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dial_img_block">
                      <img src={CardGame} alt="Cricket Image" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="dial_right_infos">
                      <h1>Master the Thrill of the 3 Card Game with Sky888</h1>
                      <p>
                        Sky888 is your prime choice for a superior online gaming
                        experience where cutting-edge technology meets unmatched
                        entertainment. At Sky888, we pride ourselves on offering
                        a diverse and thrilling range of games designed to cater
                        to all types of players, from casual gamers to seasoned
                        pros. Among our extensive selection, one game that
                        consistently captivates our community with its perfect
                        blend of simplicity and excitement is the{" "}
                        <b>3 Card Game.</b> Our 3 Card Game is not just another
                        card game; it’s an immersive experience combining
                        traditional poker's elegance with the fast-paced action
                        that modern gamers crave.
                      </p>

                      <p>
                        At Sky888, we have meticulously designed{" "}
                        <b>3 card game online</b> to ensure that it delivers the
                        maximum thrill, with every round offering a fresh chance
                        to outsmart the dealer and come out on top. Let's dive
                        into what makes the 3 Card Game at Sky888 an
                        unparalleled choice for your gaming adventure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dial_first_block dial_first_block_more">
                <h2>What is the 3 Card Game?</h2>
                <p>
                  The 3 Card Game, also known as <b>3 card poker</b>, is a
                  popular casino game that offers fast-paced action and multiple
                  ways to win. It’s a simplified version of poker where players
                  compete against the dealer rather than other players. The game
                  is easy to learn yet offers enough depth to keep even the most
                  seasoned players engaged. Unlike regular poker,{" "}
                  <b>3 card poker</b> has each player trying to beat the dealer
                  or get dealt a good hand rather than compete against each
                  other. Both games require minimal setup and can easily be
                  played at home.
                </p>
                <h2>3 Cards Game Rules for Beginners</h2>

                <p>
                  It is essential to schedule time for engaging performances,
                  eat at unique restaurants, and enjoy the world-class nightlife
                  while planning a trip to Las Vegas. And on the schedule should
                  be learning how to play <b>3 card poker.</b>
                </p>
                <p>
                  Three Card Poker has become quite popular because it is so
                  easy to use and provides a lot of pleasure.{" "}
                  <b>3 cards game</b> rules allow players to compete against the
                  dealer and win depending on the strength of their hand, it
                  essentially blends two casino games into one. Making the best
                  poker hand with just three cards is the primary goal of the
                  game.
                </p>

                <h2>How to Play the 3 card Game Online</h2>
                <p>Basic Rules</p>
                <ul className="gameList">
                  <li>
                    <b>Place Your Bets: </b> The <b>3 card game online game</b>{" "}
                    begins with players placing an Ante bet. Optionally, players
                    can also place a Pair Plus bet, which is independent of the
                    Ante bet.
                  </li>
                  <li>
                    <b>Deal the Cards: </b> Each player and the dealer are dealt
                    three cards face down.
                  </li>
                  <li>
                    <b>Decide to Play or Fold : </b> After viewing their cards,
                    players must decide whether to fold or continue playing by
                    placing a Play bet equal to the Ante.
                  </li>
                  <li>
                    <b>Reveal and Compare Hands: </b> The dealer reveals their
                    cards. To qualify, the dealer must have at least a Queen
                    high. If the dealer does not qualify, the Ante bet pays even
                    money, and the Play bet is returned to the player. If the
                    dealer qualifies, hands are compared:
                    <ul className="gameList">
                      <li>
                        If the player’s hand beats the dealer’s hand, both the
                        Ante and Play bets pay even money.
                      </li>
                      <li>
                        If the dealer’s hand beats the player’s hand, both the
                        Ante and Play bets are lost.
                      </li>
                      <li>
                        If the hands are tied, both bets push (are returned to
                        the player).
                      </li>
                    </ul>
                  </li>
                </ul>

                <h2>Hand Rankings of Big 3 Card Game </h2>
                <ul className="gameList">
                  <li>
                    <b>Straight Flush:</b>Three consecutive cards of the same
                    suit.
                  </li>
                  <li>
                    <b>Straight:</b>Three consecutive cards of different suits.
                  </li>
                  <li>
                    <b>High Card:</b>
                    The highest card in the hand if no other hand is made.
                  </li>
                  <li>
                    <b>Flush:</b>
                    Three cards of the same suit.
                  </li>
                  <li>
                    <b>Pair:</b>
                    Two cards of the same rank.
                  </li>
                  <li>
                    <b>Three of a Kind:</b>
                    Three cards of the same rank.
                  </li>
                </ul>

                <h2>Why Play the 3 Card Game at Sky888?</h2>
                <p>
                  <b>Intuitive and Engaging Interface</b>
                </p>
                <p>
                  At Sky888, we focus on delivering a user-friendly and engaging
                  interface for all our games. Our <b>3 card game online</b>
                  is designed to provide a seamless experience, with clear
                  graphics and intuitive controls that make it easy for players
                  of all skill levels to dive in and enjoy the{" "}
                  <b>3 card casino game.</b>
                </p>

                <p>
                  <b>Top-Notch Security</b>
                </p>
                <p>
                  Security is our top priority. We use advanced encryption
                  technologies to ensure your personal and financial information
                  is protected. Moreover, our <b>3 card casino games</b> are
                  regularly audited to guarantee fair play and transparency,
                  allowing you to enjoy the game with complete peace of mind.
                </p>

                <p>
                  <b>Generous Bonuses and Promotions</b>
                </p>
                <p>
                  Sky888 offers a variety of bonuses and promotions to enhance
                  your gaming experience. Whether you are a new player or a
                  loyal member, you can take advantage of welcome bonuses,
                  deposit bonuses, and special promotions tailored to give you
                  more value and increase your chances of winning{" "}
                  <b>3 cards online real money.</b>
                </p>

                <p>
                  <b>24/7 Customer Support</b>
                </p>
                <p>
                  Our customer support team is available 24/7 to assist you with
                  any questions or issues you might encounter. Whether you need
                  help with account management, game rules, or technical
                  problems, our friendly and knowledgeable support staff are
                  here to ensure your experience at Sky888 is nothing short of
                  exceptional.
                </p>

                <p>
                  <b>Responsible Gaming</b>
                </p>
                <p>
                  At Sky888, we are committed to promoting responsible gaming.
                  We provide tools and resources to help you maintain control
                  over your gaming habits. Set limits on deposits, take breaks,
                  or opt for self-exclusion if needed. Our support team is
                  available to assist you with any concerns regarding
                  responsible gaming.
                </p>

                <h2>Let’s Sum Up</h2>
                <p>
                  The <b>big 3 card game</b> at Sky888 offers an exciting,
                  fast-paced gaming experience that caters to both novice and
                  experienced players. With our intuitive interface, robust
                  security measures, generous bonuses, and dedicated customer
                  support, Sky888 is the perfect platform to enjoy this
                  thrilling game. Join us today and embark on a captivating
                  journey with the <b>3 kings card game</b> at Sky888. Start
                  playing the 3 Card Game at Sky888 today and discover the
                  excitement and rewards that await you!
                </p>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_six">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Waiting for Booking</h2>
              </div>
              <div className="dial_sub_text">
                We have some great and good features due to which you will feel
                good in joining us. You will get hundred percent support from
                our side.
              </div>

              <div className="dial_waiting_grid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon dial_waiting_icon_small">
                        <img src={WaitingIcon1} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Safe & Secure</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon2} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Active Users</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon3} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Replay on Whatsapp</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dial_wgrid_img">
                      <img src={Whychoose} alt="WhychooseImage" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon4} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Branches</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon5} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>24/7 Hours Services</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon">
                        <img src={WaitingIcon6} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>On Time & Fast Delivery</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default CardGamePage;
