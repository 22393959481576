export const APP_CONST = Object.freeze({
  AUTH_ACTIONS: {
    LOGIN: "login",
    REGISTER: "register",
  },
  TOAST_TYPE: {
    ERROR: "error",
    SUCCESS: "success",
  },
  SPORTS_ID: {
    CIRCKET: "4",
    SOCCER: "1",
    TENNIS: "2",
  },
  ALL_MARKET_IDS: {
    CRICKET: "CRICKET_IDS",
    SOCCER: "SOCCER_IDS",
    TENNIS: "TENNIS_IDS",
  },
  ODDS_TYPE: {
    BACK: "back",
    LAY: "lay",
  },
  SPORTS_CATEGORY: {
    CRICKET: "cricket",
    TENNIS: "tennis",
    SOCCER: "soccer",
    INPLAY: "inplay",
  },
  PLAYER_BANK_DETAILS_TYPE: {
    Bank: "bank",
    Card: "card",
  },

  LIVE_CHAT_SCRIPT: `
  window.__lc = window.__lc || {};
  window.__lc.license = 17804586;
  ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
  `,
});
