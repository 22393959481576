import React, { PureComponent, useEffect } from "react";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import { Link } from "react-router-dom";
import CoinToss from "../../../../assets/images/static_pages/cointosssImg.jpg";
import CrickSec2 from "../../../../assets/images/static_pages/fairbet7.jpg";
import CrickSec3 from "../../../../assets/images/static_pages/diamond_exch.jpg";
import CrickSec4 from "../../../../assets/images/static_pages/play.jpg";
import CrickSec5 from "../../../../assets/images/static_pages/sky_exchange.jpg";
import CrickSec6 from "../../../../assets/images/static_pages/world777.jpg";
import CrickSec7 from "../../../../assets/images/static_pages/lotus.jpg";
import CrickSec8 from "../../../../assets/images/static_pages/goldbet7.jpg";
import WhatsAppIcon from "../../../../assets/images/static_pages/whatsapp.png";
import Partner1 from "../../../../assets/images/static_pages/cricket.jpg";
import Partner2 from "../../../../assets/images/static_pages/football.jpg";
import Partner3 from "../../../../assets/images/static_pages/card_games.jpg";
import Partner4 from "../../../../assets/images/static_pages/teenpati.jpg";
import Partner5 from "../../../../assets/images/static_pages/casino.jpg";
import Partner6 from "../../../../assets/images/static_pages/binary.jpg";
import Partner7 from "../../../../assets/images/static_pages/slot-games.jpg";
import Partner8 from "../../../../assets/images/static_pages/horse_racing.jpg";
import BestIcon1 from "../../../../assets/images/static_pages/da_icon1.png";
import BestIcon2 from "../../../../assets/images/static_pages/da_icon2.png";
import BestIcon3 from "../../../../assets/images/static_pages/da_icon3.png";
import BestIcon4 from "../../../../assets/images/static_pages/da_icon4.png";
import BestIcon5 from "../../../../assets/images/static_pages/da_icon5.png";
import BestIcon6 from "../../../../assets/images/static_pages/da_icon6.png";
import BestIcon7 from "../../../../assets/images/static_pages/da_icon7.png";
import BestIcon8 from "../../../../assets/images/static_pages/da_icon8.png";
import Whychoose from "../../../../assets/images/static_pages/whychoose_bg2.png";
import WaitingIcon1 from "../../../../assets/images/static_pages/dw_icon1.png";
import WaitingIcon2 from "../../../../assets/images/static_pages/dw_icon2.png";
import WaitingIcon3 from "../../../../assets/images/static_pages/dw_icon3.png";
import WaitingIcon4 from "../../../../assets/images/static_pages/dw_icon4.png";
import WaitingIcon5 from "../../../../assets/images/static_pages/dw_icon5.png";
import WaitingIcon6 from "../../../../assets/images/static_pages/dw_icon6.png";
import { addMetaTags } from "../../../../utils/addMetaTags";

const CoinTossPage = () => {
  useEffect(() => {
    addMetaTags({
      title:
        "Play Coin Toss Game Online | Coin Flip Betting Game In India | Sky888",
      content:
        "Coin toss game online offers entertainment and earning opportunities! Play virtual coin flip game and challenge yourself to earn real money rewards at Sky888. Explore coin toss and other betting game.",
      canonicalUrl: "https://sky888.com/coin-toss",
    });
  }, []);
  console.log("test", document.querySelector("link[rel='canonical']"));
  // document
  //   .querySelector("link[rel='canonical']")
  //   .setAttribute("href", "https://sky888.com/cricket-betting/");
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main">
          <section className="dial_slider_main dial_slider_main_cointoss">
            <div className="container"></div>
          </section>

          <section className="dial_sec_space dial_sec_two">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Top Betting Id</h2>
              </div>

              <div className="dial_sec_grid_betting_id">
                <ul>
                  <li>
                    <img src={CrickSec2} alt="Fairbet7" />
                  </li>
                  <li>
                    <img src={CrickSec3} alt="Diamond Exch" />
                  </li>
                  <li>
                    <img src={CrickSec4} alt="Play" />
                  </li>
                  <li>
                    <img src={CrickSec5} alt="Sky Exchange" />
                  </li>
                  <li>
                    <img src={CrickSec6} alt="World 777" />
                  </li>
                  <li>
                    <img src={CrickSec7} alt="Lotus" />
                  </li>
                  <li>
                    <img src={CrickSec8} alt="Goldbet7" />
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_three">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Our Partners</h2>
              </div>

              <div className="dial_partners_grid">
                <div className="row">
                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Cricket</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner1} alt="Cricket" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Sportsbook</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner2} alt="Football" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Card Games</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner3} alt="Card Games" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Teenpatti Multiplayer</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner4} alt="Teen Pati" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Casino</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner5} alt="Casino" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Binary</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner6} alt="Binary" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Slot Games</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner7} alt="Slot Games" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Greyhound & Horse Racing</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner8} alt="Horse Racing" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_four">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Why Choose Us</h2>
              </div>

              <div className="dial_whychoose_grid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="whychoose_box">
                      <div className="why_choose_title">
                        <h4>Best Achievements</h4>
                      </div>

                      <div className="why_choose_grid">
                        <ul>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon1} alt="Icon" />
                            </span>
                            <span className="dial_icontext">Winners</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon2} alt="Icon" />
                            </span>
                            <span className="dial_icontext">24x7 Support</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon3} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              100% Trusted Platform
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon4} alt="Icon" />
                            </span>
                            <span className="dial_icontext">24M+ Users</span>
                          </li>
                        </ul>
                        <div className="dial_choose_btn">
                          <Link to="/">Win Big</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="whychoose_box whychoose_box2">
                      <div className="why_choose_title">
                        <h4>Why Choose Us</h4>
                      </div>
                      <div className="why_choose_grid">
                        <ul>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon5} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Instant Withdrawal
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon6} alt="Icon" />
                            </span>
                            <span className="dial_icontext">Best Platform</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon7} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Players Online
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon8} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Working Since 2010
                            </span>
                          </li>
                        </ul>
                        <div className="dial_choose_btn">
                          <Link to="/">Click Get Your ID</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="dial_sec_space dial_sec_five">
            <div className="container">
              <div className="dial_ads_infos">
                <h3>Fast Withdrawal Get 15% cashback Betting Id</h3>
                <p>
                  This is a very easy way to withdraw cash and we are providing
                  the best offer for new users.
                </p>
                <div className="ads_btns">
                  <Link to="/">+9198888899999</Link>
                  <Link to="/" className="dial_booknow">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_one">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Coin Toss </h2>
              </div>
              <div className="dial_first_block">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dial_img_block">
                      <img src={CoinToss} alt="Cricket Image" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="dial_right_infos">
                      <h1>Coin Tails Game: Experience the Thrill at Sky888</h1>
                      <p>
                        The <b>coin tails game</b> is one of the simplest yet
                        most thrilling games available online. At Sky888 , we
                        offer a seamless and exciting platform for you to engage
                        in this classic game of chance. Whether you’re a
                        seasoned gambler or a newcomer looking for some quick
                        fun, our platform provides an engaging experience that
                        keeps you coming back for more{" "}
                        <b>coin toss game online.</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dial_first_block dial_first_block_more">
                <h2>Flip Coin Online: A Modern Twist on a Classic Game</h2>
                <p>
                  In the digital age, many traditional games have found a new
                  life online. The
                  <b> flip coin online</b> game is a perfect example of this
                  transformation. With just a click of a button, you can enjoy
                  the excitement of a <b>coin toss online</b>without needing a
                  physical coin. Our platform at Sky888 offers a user-friendly
                  interface that makes it easy for you to enjoy a quick coin
                  flip whenever you want. Whether you’re looking to pass the
                  time or make a small wager, flipping a coin online provides
                  instant gratification and a burst of excitement.
                </p>

                <h2>Coin Toss Online: Simple, Fast, and Fun </h2>
                <p>
                  The <b> coin toss online</b>game is all about simplicity.
                  There are no complex rules or strategies involved; it’s just
                  you and the coin. At Sky888, we’ve taken this simplicity and
                  turned it into an engaging online experience. Our platform
                  allows you to participate in a <b>virtual coin toss</b> from
                  the comfort of your home. The thrill of seeing the coin land
                  on heads or tails never gets old, and with our secure and fair
                  gaming environment, you can trust that each{" "}
                  <b>virtual coin toss</b> is completely random and unbiased.
                </p>

                <h2>Coin Flip Game: The Ultimate Test of Luck</h2>
                <p>
                  The <b>coin flip game</b>is a pure test of luck. There’s no
                  skill involved, making it accessible to everyone. At Sky888 ,
                  we offer a variety of coin flip games that cater to different
                  preferences and betting styles. Whether you prefer a
                  straightforward flip or a more interactive{" "}
                  <b> heads and tails coin toss,</b> our platform has something
                  for everyone. The instant results and potential for quick wins
                  make the <b>coin flip game</b> a favorite among our users.
                </p>

                <h2>
                  Classic Fun with a Modern Twist in Heads and Tails Coin Toss
                </h2>
                <p>
                  The <b>heads and tails coin toss</b> is a classic game that
                  has been enjoyed for generations. At Sky888 , we’ve brought
                  this timeless game into the modern era with our online
                  platform. You can now enjoy the excitement of a{" "}
                  <b>heads and tails coin toss</b> anytime, anywhere. Our
                  intuitive interface and seamless gameplay ensure that you can
                  quickly place your bet and see the result. The simplicity and
                  quick turnaround make this <b>coin toss online</b> game
                  perfect for those looking for a quick and fun betting
                  experience.
                </p>

                <h2>Engage in Instant Fun with Coin Toss Game Online</h2>
                <p>
                  Participating in a <b>coin toss game online</b>at Sky888 is as
                  easy as it gets. Simply log in to your account, choose your
                  bet, and flip the <b>virtual coin toss.</b> The results are
                  instantaneous, providing immediate feedback and excitement.
                  Our platform is designed to be user-friendly, ensuring that
                  even those new to online gaming can easily navigate and enjoy
                  the experience. Whether you’re betting for fun or looking to
                  win big, our <b>coin toss game online</b> offers endless
                  entertainment.
                </p>

                <h2>Sky888 for Your Coin Tails Game Experience</h2>
                <p>
                  At Sky888 , we prioritize providing a top-notch gaming
                  experience for all our users. Here’s why our platform is the
                  best choice for enjoying the <b>coin tails game</b> and other
                  online betting activities:
                </p>
                <ul className="gameList">
                  <li>
                    <b>User-Friendly Interface : </b> Our platform is designed
                    to be intuitive and easy to navigate, ensuring that you can
                    quickly find and play your favorite <b>virtual coin toss</b>
                    games
                  </li>
                  <li>
                    <b>Fair and Secure Gaming : </b> We use advanced algorithms
                    to ensure that all our games, including the{" "}
                    <b>coin flip game,</b>
                    are fair and random. Your safety and security are our top
                    priorities.
                  </li>
                  <li>
                    <b>Instant Results : </b> The nature of the
                    <b>coin flip game</b>means you get instant results,
                    providing immediate excitement and gratification.
                  </li>
                  <li>
                    <b>Variety of Games : </b> In addition to the{" "}
                    <b>coin tails game,</b>we offer a wide range of other games
                    to keep you entertained.
                  </li>
                  <li>
                    <b>Convenience : </b> Play from anywhere, at any time, using
                    your computer or mobile device. Our platform is accessible
                    and convenient, allowing you to enjoy a
                    <b>virtual coin toss</b>whenever you want.
                  </li>
                </ul>

                <p>
                  The <b>flip coin online</b> offers a unique blend of
                  simplicity and excitement that appeals to a wide range of
                  players. At Sky888, we’ve brought this classic game into the
                  digital age, providing a seamless and engaging online
                  experience. Whether you’re looking to <b>flip coin online,</b>{" "}
                  enjoy a <b>coin toss online,</b> or engage in a{" "}
                  <b>heads and tails coin toss, </b> our platform has everything
                  you need for a fun and thrilling gaming session.
                </p>

                <p>
                  Join Sky888 today and discover why the <b>flip coin online</b>{" "}
                  is one of the most popular choices among online gamblers. With
                  our secure and user-friendly platform, you can enjoy the
                  excitement of a <b>coin toss game online</b> and experience
                  the instant thrill of predicting heads or tails. Don’t miss
                  out on the fun – start playing now and see if luck is on your
                  side!
                </p>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_six">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Waiting for Booking</h2>
              </div>
              <div className="dial_sub_text">
                We have some great and good features due to which you will feel
                good in joining us. You will get hundred percent support from
                our side.
              </div>

              <div className="dial_waiting_grid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon dial_waiting_icon_small">
                        <img src={WaitingIcon1} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Safe & Secure</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon2} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Active Users</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon3} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Replay on Whatsapp</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dial_wgrid_img">
                      <img src={Whychoose} alt="WhychooseImage" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon4} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Branches</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon5} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>24/7 Hours Services</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon">
                        <img src={WaitingIcon6} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>On Time & Fast Delivery</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default CoinTossPage;
