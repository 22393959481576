export const addMetaTags = ({
  title = "",
  content = "",
  canonicalUrl = "https://sky888.com",
}) => {
  document.title = title;
  document
    ?.querySelector("meta[name='description']")
    ?.setAttribute("content", content);
  document
    .querySelector("link[rel='canonical']")
    .setAttribute("href", canonicalUrl);
};
