import React, { PureComponent, useEffect } from "react";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import { Link } from "react-router-dom";
import DragonTiger from "../../../../assets/images/static_pages/DragontigerImg.jpg";
import CrickSec2 from "../../../../assets/images/static_pages/fairbet7.jpg";
import CrickSec3 from "../../../../assets/images/static_pages/diamond_exch.jpg";
import CrickSec4 from "../../../../assets/images/static_pages/play.jpg";
import CrickSec5 from "../../../../assets/images/static_pages/sky_exchange.jpg";
import CrickSec6 from "../../../../assets/images/static_pages/world777.jpg";
import CrickSec7 from "../../../../assets/images/static_pages/lotus.jpg";
import CrickSec8 from "../../../../assets/images/static_pages/goldbet7.jpg";
import WhatsAppIcon from "../../../../assets/images/static_pages/whatsapp.png";
import Partner1 from "../../../../assets/images/static_pages/cricket.jpg";
import Partner2 from "../../../../assets/images/static_pages/football.jpg";
import Partner3 from "../../../../assets/images/static_pages/card_games.jpg";
import Partner4 from "../../../../assets/images/static_pages/teenpati.jpg";
import Partner5 from "../../../../assets/images/static_pages/casino.jpg";
import Partner6 from "../../../../assets/images/static_pages/binary.jpg";
import Partner7 from "../../../../assets/images/static_pages/slot-games.jpg";
import Partner8 from "../../../../assets/images/static_pages/horse_racing.jpg";
import BestIcon1 from "../../../../assets/images/static_pages/da_icon1.png";
import BestIcon2 from "../../../../assets/images/static_pages/da_icon2.png";
import BestIcon3 from "../../../../assets/images/static_pages/da_icon3.png";
import BestIcon4 from "../../../../assets/images/static_pages/da_icon4.png";
import BestIcon5 from "../../../../assets/images/static_pages/da_icon5.png";
import BestIcon6 from "../../../../assets/images/static_pages/da_icon6.png";
import BestIcon7 from "../../../../assets/images/static_pages/da_icon7.png";
import BestIcon8 from "../../../../assets/images/static_pages/da_icon8.png";
import Whychoose from "../../../../assets/images/static_pages/whychoose_bg2.png";
import WaitingIcon1 from "../../../../assets/images/static_pages/dw_icon1.png";
import WaitingIcon2 from "../../../../assets/images/static_pages/dw_icon2.png";
import WaitingIcon3 from "../../../../assets/images/static_pages/dw_icon3.png";
import WaitingIcon4 from "../../../../assets/images/static_pages/dw_icon4.png";
import WaitingIcon5 from "../../../../assets/images/static_pages/dw_icon5.png";
import WaitingIcon6 from "../../../../assets/images/static_pages/dw_icon6.png";
import { addMetaTags } from "../../../../utils/addMetaTags";

const DragonTigerPage = () => {
  useEffect(() => {
    addMetaTags({
      title:
        "Play Dragon Tiger Game Online in India | Dragon Tiger Card Game | Sky888",
      content:
        "Get ready for endless fun with dragon tiger game. Find the online dragon tiger game free for an immersive gaming adventure With Sky888. Play dragon tiger card game now!",
      canonicalUrl: "https://sky888.com/dragon-tiger-game",
    });
  }, []);
  console.log("test", document.querySelector("link[rel='canonical']"));
  // document
  //   .querySelector("link[rel='canonical']")
  //   .setAttribute("href", "https://sky888.com/cricket-betting/");
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main">
          <section className="dial_slider_main dial_slider_main_dragontiger">
            <div className="container"></div>
          </section>

          <section className="dial_sec_space dial_sec_two">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Top Betting Id</h2>
              </div>

              <div className="dial_sec_grid_betting_id">
                <ul>
                  <li>
                    <img src={CrickSec2} alt="Fairbet7" />
                  </li>
                  <li>
                    <img src={CrickSec3} alt="Diamond Exch" />
                  </li>
                  <li>
                    <img src={CrickSec4} alt="Play" />
                  </li>
                  <li>
                    <img src={CrickSec5} alt="Sky Exchange" />
                  </li>
                  <li>
                    <img src={CrickSec6} alt="World 777" />
                  </li>
                  <li>
                    <img src={CrickSec7} alt="Lotus" />
                  </li>
                  <li>
                    <img src={CrickSec8} alt="Goldbet7" />
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_three">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Our Partners</h2>
              </div>

              <div className="dial_partners_grid">
                <div className="row">
                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Cricket</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner1} alt="Cricket" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Sportsbook</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner2} alt="Football" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Card Games</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner3} alt="Card Games" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Teenpatti Multiplayer</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner4} alt="Teen Pati" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Casino</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner5} alt="Casino" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Binary</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner6} alt="Binary" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Slot Games</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner7} alt="Slot Games" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Greyhound & Horse Racing</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner8} alt="Horse Racing" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_four">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Why Choose Us</h2>
              </div>

              <div className="dial_whychoose_grid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="whychoose_box">
                      <div className="why_choose_title">
                        <h4>Best Achievements</h4>
                      </div>

                      <div className="why_choose_grid">
                        <ul>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon1} alt="Icon" />
                            </span>
                            <span className="dial_icontext">Winners</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon2} alt="Icon" />
                            </span>
                            <span className="dial_icontext">24x7 Support</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon3} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              100% Trusted Platform
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon4} alt="Icon" />
                            </span>
                            <span className="dial_icontext">24M+ Users</span>
                          </li>
                        </ul>
                        <div className="dial_choose_btn">
                          <Link to="/">Win Big</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="whychoose_box whychoose_box2">
                      <div className="why_choose_title">
                        <h4>Why Choose Us</h4>
                      </div>
                      <div className="why_choose_grid">
                        <ul>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon5} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Instant Withdrawal
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon6} alt="Icon" />
                            </span>
                            <span className="dial_icontext">Best Platform</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon7} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Players Online
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon8} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Working Since 2010
                            </span>
                          </li>
                        </ul>
                        <div className="dial_choose_btn">
                          <Link to="/">Click Get Your ID</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="dial_sec_space dial_sec_five">
            <div className="container">
              <div className="dial_ads_infos">
                <h3>Fast Withdrawal Get 15% cashback Betting Id</h3>
                <p>
                  This is a very easy way to withdraw cash and we are providing
                  the best offer for new users.
                </p>
                <div className="ads_btns">
                  <Link to="/">+9198888899999</Link>
                  <Link to="/" className="dial_booknow">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_one">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Dragon Tiger</h2>
              </div>
              <div className="dial_first_block">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dial_img_block">
                      <img src={DragonTiger} alt="Cricket Image" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="dial_right_infos">
                      <h1>
                        Sky888: Your Ultimate Online Dragon Tiger Game
                        Experience
                      </h1>
                      <p>
                        Welcome to Sky888, the premier destination for online
                        gaming enthusiasts who seek thrilling, fast-paced, and
                        rewarding experiences. Among our diverse range of games,
                        the Dragon Tiger game stands out as a favorite for many
                        players around the globe. If you are looking to dive
                        into an exciting card game that combines simplicity with
                        high stakes, you are in the right place. You can find
                        out everything about the <b>Dragon Tiger game</b> at
                        Sky888. This is the way to go if you're searching for a
                        quick-paced casino game. Due to its simplicity, the game
                        is very well-liked and is the preferred choice for many
                        gamers. Let’s explore what makes <b>Dragon Tiger</b>
                        at Sky888 the best choice for your gaming adventure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dial_first_block dial_first_block_more">
                <h2>An Overview of Dragon Tiger Game Online</h2>
                <p>
                  Before you read the regulations, here is a little history of
                  the
                  <b>Dragon Tiger game online.</b>
                </p>
                <p>
                  There is a strong suspicion that this game originated in
                  Cambodia. Over time, several real casinos and online casinos
                  have been established to increase the game's popularity among
                  the general population. The rules of the{" "}
                  <b>Dragon Tiger game online</b> are simple. The standard
                  52-card deck used to play this game does not contain jokers or
                  wildcards. The game's object is to collect the card with the
                  highest value, regardless of its suit.
                </p>
                <p>
                  After receiving chips, the participants can bet on the
                  likelihood that the Tiger or the Dragon will appear on the
                  table. Additionally, the players can wager utilizing the tie
                  option. You can place multiple side bets for every game round
                  to improve your winnings. Each round ends with the
                  participants receiving their prizes. The ace is considered a
                  high-value card in most card games, but not in this one. The
                  card rankings in the<b>Dragon vs Tiger online game</b>are as
                  follows: J, Q, K, A, 2, 3, 4, 5, 6, 7, 8, 9, 10, and so on.
                  The king is better than all the other cards and has the
                  highest value of them all.
                </p>
                <p>
                  You must realize that in addition to playing against the
                  dealer, you compete with other players. There is a particular
                  spot at the table for each player to bet on a Dragon, Tiger,
                  or Tie. You receive even money, or one hundred times your
                  initial stake, for winners' payments. In addition to receiving
                  half of your original wager, you will get ten times your
                  initial stake if you bet on a tie in that specific round.
                </p>
                <h2>Why Play Dragon Tiger at Sky888?</h2>
                <h3>1. User-Friendly Interface</h3>
                <p>
                  At Sky888, we pride ourselves on delivering a seamless gaming
                  experience. Our <b>Dragon Tiger</b> game interface is designed
                  to be intuitive, making it easy for both beginners and
                  seasoned players to navigate. You can enjoy the game without
                  any hassle with clear graphics, simple controls, and a
                  responsive design.
                </p>

                <h3>2. Secure and Fair Play</h3>
                <p>
                  We understand that security and fairness are paramount for our
                  players. Sky888 employs advanced encryption technology to
                  protect your personal and financial information. Additionally,
                  our <b>Dragon Tiger Online</b>uses a certified Random Number
                  Generator (RNG) to ensure that every deal is random and fair,
                  giving you peace of mind as you play. Due to this, anyone can
                  not use <b>Dragon Tiger Casino tricks</b>to win the game.
                </p>

                <h3>3. Exciting Bonuses and Promotions</h3>
                <p>
                  To enhance your gaming experience, Sky888 offers a variety of
                  bonuses and promotions. From welcome bonuses for new players
                  to regular promotions for our loyal members, there are plenty
                  of opportunities to boost your bankroll and increase your
                  chances of winning. Keep an eye on our promotions page to make
                  the most of these offers and play
                  <b>Dragon Tiger free online.</b>
                </p>

                <h3>4. 24/7 Customer Support</h3>
                <p>
                  Our dedicated customer support team is available around the
                  clock to assist you with any queries or issues you might
                  encounter. Whether you need help with your account, have
                  questions about the game, or face technical difficulties, our
                  friendly and professional support staff are here to ensure
                  your experience at Sky888 is smooth and enjoyable. This makes
                  our game the <b> best Dragon Tiger game.</b>
                </p>

                <h2>The Fundamental Rules of the Dragon Tiger Game</h2>

                <p>
                  There are not many complicated rules for the{" "}
                  <b>Dragon Tiger game online.</b>Each round starts with the
                  dealing of two cards: one to the Tiger betting position and
                  one to the Dragon betting position. Regardless of the suit,
                  the position that reveals the card with the highest value
                  wins. Your objective is to place a wager in the hopes that the
                  Dragon or the Tiger will turn out to be the winning card.
                </p>

                <h2>Different Payouts and Bets in Tiger Vs Dragon Game</h2>
                <p>
                  There are several methods to gamble in the game Tiger Dragon.
                  The contesting players contend not only with the dealer but
                  also with each other to decide the rewards. The following
                  details are something you should be aware of: You get chips to
                  utilize on the Dragon or Tiger during the betting procedure.
                  Furthermore, you can place a wager on a tie. If your tie
                  prediction is accurate, you will get an additional prize.
                </p>

                <h3>Let’s Sum Up</h3>
                <p>
                  Dragon Tiger at Sky888 offers an exhilarating and
                  straightforward gaming experience that is perfect for both
                  novice and experienced players. With our user-friendly
                  interface, secure and fair play, exciting bonuses, and
                  dedicated customer support, Sky888 is your go-to platform for
                  online Dragon Tiger gaming. Dive into the action today and
                  discover the thrill of Dragon Tiger at Sky888. Join us now and
                  be part of a dynamic and rewarding gaming community!
                </p>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_six">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Waiting for Booking</h2>
              </div>
              <div className="dial_sub_text">
                We have some great and good features due to which you will feel
                good in joining us. You will get hundred percent support from
                our side.
              </div>

              <div className="dial_waiting_grid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon dial_waiting_icon_small">
                        <img src={WaitingIcon1} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Safe & Secure</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon2} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Active Users</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon3} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Replay on Whatsapp</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dial_wgrid_img">
                      <img src={Whychoose} alt="WhychooseImage" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon4} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Branches</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon5} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>24/7 Hours Services</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon">
                        <img src={WaitingIcon6} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>On Time & Fast Delivery</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default DragonTigerPage;
